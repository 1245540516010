<template>
  <v-card elevation="4">
    <v-card-title class="d-flex justify-space-between">
      <h5>
        <v-icon size="24" class="mr-4" color="primary">$restorebackup</v-icon>
        {{ $t('heading.instance.automaticBackup.title') }}
      </h5>
      <v-btn v-if="!submitting" icon x-small @click="$emit('modal-close')">
        <v-icon size="24">$close</v-icon></v-btn
      >
    </v-card-title>

    <v-card-text>
      <div>
        <v-form ref="form" class="d-flex flex-column">
          <div class="d-flex flex-column mb-6">
            <label class="base--text mb-2 p-2 font-weight-600">
              {{ $t('form.label.howOften') }}
              <form-help-icon
                :text="$t('tooltip.automatic_backup.frequency')"
              />
            </label>

            <!-- FREQUENCY CHECKBOXES -->
            <div class="d-flex checkbox-row">
              <checkbox-big
                :checked="data.frequency === 'daily' || data.frequency === 'never'"
                :title="$t('form.option.frequency.daily')"
                @click.native="data.frequency = 'daily'"
              >
                <template>
                  <daily-backup />
                </template>
              </checkbox-big>
              <checkbox-big
                :checked="
                  data.frequency === 'weekly'
                "
                :title="$t('form.option.frequency.weekly')"
                @click.native="data.frequency = 'weekly'"
              >
                <template>
                  <weekly-backup />
                </template>
              </checkbox-big>

              <checkbox-big
                :checked="data.frequency === 'monthly'"
                :title="$t('form.option.frequency.monthly')"
                @click.native="data.frequency = 'monthly'"
              >
                <template>
                  <monthly-backup />
                </template>
              </checkbox-big>
            </div>
          </div>

          <div class="d-flex flex-column" v-if="data.frequency === 'daily'">
            <label class="base--text mb-2 p-2 font-weight-600">
              {{ $t('form.label.chooseTime') }}
              <form-help-icon
                :text="$t('tooltip.automatic_backup.choose_time')"
              />
            </label>

            <v-autocomplete
              outlined
              hide-details="auto"
              :items="hoursArray"
              :item-value="time"
              v-model="time"
            >
              <template v-slot:item="{ item, on, attrs }">
                <div class="input-dropdown-item" v-on="on" v-bind="attrs">
                  <span>{{ item }}</span>
                  <v-icon class="ml-2 input-dropdown-item__icon">$check</v-icon>
                </div>
              </template>
              <template v-slot:prepend-inner>
                <v-icon size="24">$clock</v-icon>
              </template>
            </v-autocomplete>
          </div>

          <div
            class="d-flex flex-column"
            v-else-if="data.frequency === 'weekly'"
          >
            <label class="base--text mb-2 p-2 font-weight-600">
              {{ $t('form.label.chooseDay') }}
              <form-help-icon
                :text="$t('tooltip.automatic_backup.choose_day')"
              />
            </label>

            <div class="d-flex justify-space-between">
              <checkbox-big
                :title="$t('form.option.days.monday')"
                :checked="
                  (data.cron_day_of_week === 1 ||
                    data.cron_day_of_week === '1') &&
                  parseInt(data.cron_day_of_week) === 1
                "
                @click.native="data.cron_day_of_week = 1"
              />
              <checkbox-big
                :title="$t('form.option.days.tuesday')"
                :checked="
                  data.cron_day_of_week === 2 ||
                  parseInt(data.cron_day_of_week) === 2
                "
                @click.native="data.cron_day_of_week = 2"
              />
              <checkbox-big
                :title="$t('form.option.days.wednesday')"
                :checked="
                  data.cron_day_of_week === 3 ||
                  parseInt(data.cron_day_of_week) === 3
                "
                @click.native="data.cron_day_of_week = 3"
              />
              <checkbox-big
                :title="$t('form.option.days.thursday')"
                :checked="
                  data.cron_day_of_week === 4 ||
                  parseInt(data.cron_day_of_week) === 4
                "
                @click.native="data.cron_day_of_week = 4"
              />
              <checkbox-big
                :title="$t('form.option.days.friday')"
                :checked="
                  data.cron_day_of_week === 5 ||
                  parseInt(data.cron_day_of_week) === 5
                "
                @click.native="data.cron_day_of_week = 5"
              />
              <checkbox-big
                :title="$t('form.option.days.saturday')"
                :checked="
                  data.cron_day_of_week === 6 ||
                  parseInt(data.cron_day_of_week) === 6
                "
                @click.native="data.cron_day_of_week = 6"
              />
              <checkbox-big
                :title="$t('form.option.days.sunday')"
                :checked="
                  data.cron_day_of_week === 0 ||
                  parseInt(data.cron_day_of_week) === 0
                "
                @click.native="data.cron_day_of_week = 0"
              />
            </div>

            <label class="base--text p-2 pb-2 font-weight-600 mt-6">
              {{ $t('form.label.chooseTime') }}
              <form-help-icon
                :text="$t('tooltip.automatic_backup.choose_time')"
              />
            </label>

            <v-autocomplete
              outlined
              hide-details="auto"
              :items="hoursArray"
              :item-value="time"
              v-model="time"
            >
              <template v-slot:item="{ item, on, attrs }">
                <div class="input-dropdown-item" v-on="on" v-bind="attrs">
                  <span>{{ item }}</span>
                  <v-icon class="ml-2 input-dropdown-item__icon">$check</v-icon>
                </div>
              </template>
              <template v-slot:prepend-inner>
                <v-icon size="24">$clock</v-icon>
              </template>
            </v-autocomplete>
          </div>

          <div
            class="d-flex flex-column"
            v-else-if="data.frequency === 'monthly'"
          >
            <label class="base--text mb-2 p-2 font-weight-600">
              {{ $t('form.label.chooseDay') }}
              <form-help-icon
                :text="$t('tooltip.automatic_backup.choose_day')"
              />
            </label>

            <v-autocomplete
              v-model="parsedMonth"
              :items="monthDays"
              outlined
              hide-details="auto"
              :menu-props="{
                offsetY: true,
              }"
            >
              <template v-slot:item="{ item, on, attrs }">
                <div class="input-dropdown-item" v-on="on" v-bind="attrs">
                  <span>{{ item }}</span>
                  <v-icon class="ml-2 input-dropdown-item__icon">$check</v-icon>
                </div>
              </template>
            </v-autocomplete>

            <label class="base--text mb-2 p-2 font-weight-600">
              {{ $t('form.label.chooseTime') }}
              <form-help-icon :text="$t('tooltip.automatic_backup.choose_time')" />
            </label>
            <v-autocomplete
              outlined
              hide-details="auto"
              :items="hoursArray"
              :item-value="time"
              v-model="time"
            >
              <template v-slot:item="{ item, on, attrs }">
                <div class="input-dropdown-item" v-on="on" v-bind="attrs">
                  <span>{{ item }}</span>
                  <v-icon class="ml-2 input-dropdown-item__icon">$check</v-icon>
                </div>
              </template>
              <template v-slot:prepend-inner>
                <v-icon size="24">$clock</v-icon>
              </template>
            </v-autocomplete>
          </div>
        </v-form>
      </div>
    </v-card-text>

    <v-card-actions class="d-flex flex-wrap pb-2">
      <div class="w-100 pb-2">
        <v-btn
          large
          block
          elevation="0"
          class="primary"
          @click="submitSettings"
          :loading="submitting"
        >
          <template v-slot:loader>
            <loader size="24" color="white" />
          </template>
          <span v-if="!edit" class="p-2 font-weight-600"
            >{{ $t('button.automaticBackup.enable') }}</span
          >
          <span v-else class="p-2 font-weight-600">{{ $t('button.automaticBackup.edit') }}</span>
        </v-btn>
        <v-btn
          text
          large
          block
          elevation="0"
          class="normal mt-2"
          @click="$emit('modal-close')"
        >
          <span class="p-2 font-weight-light gray--text text--darken-1"
            >{{ $t('button.cancel') }}</span
          >
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormHelpIcon from "../FormHelpIcon.vue";
import Loader from "@/components/Loader.vue";
import CheckboxBig from "../buttons/CheckboxBig.vue";
import DailyBackup from "../illustrations/daily-backup.vue";
import WeeklyBackup from "../illustrations/weekly-backup.vue";
import MonthlyBackup from "../illustrations/monthly-backup.vue";

import FormRulesMixin from "../../mixins/FormRulesMixin";

import { submitAutoBackupsSettings } from "../../router/fetchData";

export default {
  mixins: [FormRulesMixin],
  components: {
    FormHelpIcon,
    Loader,
    CheckboxBig,

    DailyBackup,
    WeeklyBackup,
    MonthlyBackup,
  },
  data: function () {
    return {
      submitting: false,
      chooseDayFrequency: 1,
      availableDayFrequency: [1, 2, 3, 4, 5, 6, 7],
      chooseWeekFrequency: 0,
      frequency: this.enabledFrequency,
      radioMonthly: 0,
      data: {},
      hoursArray: [],
    };
  },
  props: {
    open: Boolean,
    instance: Object,
    settings: Object,
    enabledFrequency: String,
    edit: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount: function () {
    this.data = { ...this.settings };
    if (this.data.frequency == "never") {
      this.data.frequency = "daily";
    }
  },
  methods: {
    createHoursArray: function () {
      for (let x = 0; x < 2; x++) {
        for (let i = 0; i < 12; i++) {
          for (let j = 0; j < 4; j++) {
            this.hoursArray.push(
              `${i}:${j === 0 ? "00" : 15 * j}${x ? " " + this.$t('general.pm') : " " + this.$t('general.am') }`
            );
          }
        }
      }
    },
    checkboxCheck: function (item) {
      if (item.name === "confirm") {
        this.$set(this.formValues, "confirm", !this.formValues["confirm"]);
      } else {
        this.formValues[item.name] = !this.formValues[item.name];
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    submitSettings: async function () {
      this.submitting = true;

      const response = await submitAutoBackupsSettings(this.$route.params.id, {
        ...this.data,
        frequency: this.data.frequency,
      });

      this.$emit("reload-settings");

      if (response.success) {
        if (this.edit) {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.autoBackup.edit.success')
          });
        } else {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.autoBackup.enabled.success')
          });
        }
      } else {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: response.error,
        });
      }

      this.$emit("modal-close");
      this.submitting = false;
    },
  },
  mounted: function () {
    this.createHoursArray();
  },
  watch: {
    frequency: function () {
      this.data.cron_day_of_month = "*";
      this.data.cron_day_of_week = "*";
    },
    open: function () {
      this.data = { ...this.settings };
      if (this.data.frequency == "never") {
        this.data.frequency = "daily";
      }
    },
  },
  computed: {
    monthDays: function () {
      const days = Array.from(Array(28).keys()).map((item) => {
        return item + 1;
      });
      return days;
    },
    parsedMonth: {
      get: function () {
        return parseInt(this.data.cron_day_of_month);
      },
      set: function (newValue) {
        this.data.cron_day_of_month = newValue;
      },
    },
    time: {
      get: function () {
        let minute = this.data.cron_minute;

        let hour = 0;
        let am = "AM";
        if (this.data.cron_hour / 12 > 1) {
          hour = this.data.cron_hour - 12;
          am = "PM";
        } else {
          hour = this.data.cron_hour;
        }
        minute = minute.toString().padStart(2, "0");

        return `${hour}:${minute} ${am}`;
      },
      set: function (newValue) {
        if (newValue && newValue !== ":" && newValue.indexOf(":")) {
          let split = newValue.split(":");

          let isPm = split[1].indexOf("PM") > 0;

          let hour = 0;

          if (split[0] && split[0] !== "*") {
            hour = parseInt(split[0]);
          } else {
            hour = split[0];
          }

          this.data.cron_hour = isPm ? hour + 12 : hour;

          if (split[1] && split[1] !== "*") {
            this.data.cron_minute = parseInt(split[1]);
          } else {
            this.data.cron_hour = split[1];
          }
        } else {
          let split = newValue.split(":");

          if (split[0] !== "*") {
            this.data.cron_hour = parseInt(split[0]);
          } else {
            this.data.cron_hour = split[0];
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.v-autocomplete__content {
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
    color: map-get($primary, base);
    .input-dropdown-item {
      color: map-get($primary, darken1);
    }
  }
}
</style>

<style lang="scss" scoped>
.checkbox-row {
  display: flex;
  flex-direction: row;
  .checkbox-big {
    width: 100%;
    + .checkbox-big {
      margin-left: 16px;
    }
  }
}

.v-dialog {
  .v-card {
    .v-card__actions {
      padding-top: 16px;
    }
    .v-card__title {
      min-height: 96px;
    }
  }
}

.checkbox::v-deep {
  .v-input--checkbox {
    .v-input__slot {
      max-height: 24px;
      min-height: unset;
    }
  }
}
.v-input::v-deep {
  .v-text-field__details {
    margin-top: 2px;
    margin-bottom: 0px !important;
  }
}
b {
    font-weight: $font-weight-semibold;
  color: map-get($text, heading);
}

.checkbox-label {
  cursor: pointer;
}

.day-select {
  max-width: 145px;
}

.v-select::v-deep {
  &.v-select--is-menu-active {
    background-color: map-get($primary, lighten4);
    .v-icon__component {
      color: map-get($primary, darken1) !important;
    }
  }
  .v-menu__content {
    display: none;
  }
  .v-input__slot {
    display: flex;
    align-items: center;
  }
}

.input-dropdown {
  &.v-select--is-menu-active {
    .v-input__append-inner {
      .v-icon {
        color: map-get($primary, darken1) !important;
        transform-origin: center;
        transform: rotate(180deg);
      }
    }
  }
}

.v-text-field__prefix {
  margin-top: 2px;
}
.v-list-item {
  &::before {
    display: none;
  }
  &:hover {
    color: map-get($primary, base);
  }
  .input-dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .v-icon {
      opacity: 0;
      transition-delay: 0.5s;
      transition: opacity 0.24s ease;
    }
  }
  .input-dropdown-item[aria-selected="true"] {
      font-weight: $font-weight-semibold;

    .v-icon {
      opacity: 1;
    }
  }
  .input-dropdown-item[aria-selected="true"] {
    color: map-get($primary, darken1);

    &::before {
      background-color: transparent;
    }
  }
}
.v-application--is-rtl{
    .checkbox-big {
        width: 100%;
        + .checkbox-big {
            margin-left: 0px;
            margin-right: 16px;
        }
    }
}
</style>
