import {
    fetchAutoBackupsSettings,
} from "../router/fetchData";

const AutomaticBackupsSettingsFetchMixin = {
    data: function () {
        return {
            automaticBackupsLoading: true,
            autoBackupSettings: {},
        }
    },
    methods: {
        reloadAutobackupSettings: function () {
            this.$nextTick(() => {
                this.loadAutoBackupsSettings();
            });
        },
        loadAutoBackupsSettings: async function () {
            this.automaticBackupsLoading = true;

            this.autoBackupSettings = await fetchAutoBackupsSettings(
                this.instance.id
            );
            this.instance.setAutomaticBackup(this.autoBackupSettings.frequency != 'never');
            this.$nextTick(() => {
                this.automaticBackupsLoading = false;
            })
        },
    },
}

export default AutomaticBackupsSettingsFetchMixin