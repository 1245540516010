<template>
  <v-card elevation="4">
    <v-card-title class="p-t-5">
      <v-icon size="24" color="error">$alertwarning</v-icon>
      <p class="p-2 ml-4 mb-0 font-weight-bold heading--text">
        {{ $t('heading.instance.siteDetails.security.automaticBackup.modal.title') }}
      </p>
    </v-card-title>

    <v-card-text class="p-t-4 d-flex">
      <p class="mb-0 p-3">
        <i18next :translation="$t('heading.instance.siteDetails.security.automaticBackup.modal.info')">
          <template #instance>
            <strong>{{ instance.title }}</strong>
          </template>
        </i18next>
      </p>
    </v-card-text>

    <v-divider class="mt-6" />

    <v-card-actions class="d-flex flex-column pt-5 pb-4">
      <div class="w-100">
        <v-btn
          small
          block
          color="error"
          elevation="0"
          @click="disableAutobackup"
          :loading="submitting"
        >
          <template v-slot:loader>
            <loader :size="'24'" color="white" />
          </template>
          <span class="font-weight-bold">{{ $t('button.disableAutomaticBackups') }}</span>
        </v-btn>
      </div>
      <div class="w-100 mt-2">
        <v-btn small block outlined color="gray" @click="$emit('card-close')">
          <span class="heading--text font-weight-bold">{{ $t('button.cancel') }}</span></v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>


<script>
import Loader from "../Loader.vue";

import { submitAutoBackupsSettings } from "../../router/fetchData";

export default {
  components: {
    Loader,
  },
  props: {
    settings: Object,
    instance: Object,
    open: Boolean,
  },
  data: function () {
    return {
      data: {},
      submitting: false,
    };
  },
  watch: {
    open: function () {
      this.data = this.settings;
    },
  },
  methods: {
    disableAutobackup: async function () {
      this.submitting = true;

      const newSettings = { ...this.settings, frequency: "never" };

      const response = await submitAutoBackupsSettings(
        this.$route.params.id,
        newSettings
      );

      if (response.success) {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t('notification.instance.automaticBackup.disable.success'),
        });
      } else {
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: response.error,
        });
      }

      this.$emit("reload-settings");
      this.submitting = false;

      this.$emit("card-close");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>